import "bootstrap/dist/css/bootstrap.min.css";
import RoutePath from "./assets/route";
import "./App.css";
import configureAmplify from "./aws/awsPool";
import { useEffect } from "react";

function App() {
  configureAmplify();

  // Dynamically load the Tawk.to script
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/67987ce73a8427326075adcd/1iilqfr08";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <RoutePath />
    </div>
  );
}

export default App;
