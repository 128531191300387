import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { getAllTrustType } from "../api/registrationApi";

const WrapperRegistration = styled.div`
  .details_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    margin-bottom: 1rem;
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    padding-top: 10px;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .reg_box {
    background: #f8f8f8;
    box-shadow: 0px 0px 20px #00000033;
    border-radius: 20px;
    border: none;
    height: 175px;
    cursor: pointer;
  }
  .reg_box:hover {
    background-color: #ff8744;
  }
  .reg_box:hover .register-as {
    color: #f8f8f8;
  }
  .reg_box:hover .register-temp {
    color: #f8f8f8;
  }
  .reg_box .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .register-as {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal bold 24px/43px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .register-temp {
    color: var(--unnamed-color-ff8744);
    text-align: center;
    font: normal normal 900 26px/30px Noto Sans;
    letter-spacing: 0px;
    color: #ff8744;
  }
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
    .register-as {
      font: normal normal bold 18px/35px Noto Sans;
    }
    .register-temp {
      font: normal normal 900 18px/30px Noto Sans;
    }
    .reg_box {
      height: 130px;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 35px/57px Noto Sans;
    }
    .bg_size {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .reg_box {
      height: 150px;
      margin-bottom: 20px;
    }
    .register-as {
      font: normal normal bold 18px/28px Noto Sans;
    }
    .register-temp {
      font: normal normal 900 18px/25px Noto Sans;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .details_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
  }
  @media screen and (max-width: 377px) {
    .register-as {
      font: normal normal bold 16px/28px Noto Sans;
    }
    .register-temp {
      font: normal normal 900 16px/25px Noto Sans;
    }
  }
`;

export default function Registration() {
  // trust Types
  const loadTrustType = useQuery(["trustTypes"], () => getAllTrustType());

  const trustType = useMemo(
    () => loadTrustType?.data?.results ?? [],
    [loadTrustType]
  );

  function ConvertFirstLatterToCapital(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <WrapperRegistration>
      <Container fluid style={{ paddingTop: "1rem" }}>
        <Container className="bg_size">
          <div className="text-center">
            <div className="details_title">Register Your Trust</div>
            <div className="bg_sub_title pb-4">
              All Temples and religious trusts can contact us by filling the
              following form to activate their account. Individual Users can
              register by downloading app from iOS or google play store.
            </div>
          </div>
          <Row className="row-cols-4 ">
            {trustType?.map((item, index) => {
              return (
                <Col
                  md={4}
                  sm={6}
                  xs={6}
                  key={index}
                  className="mt-4"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Link
                    to={`/register-now?trust-type-name=${item?.name}&id=${item?.id}`}
                  >
                    <Card className="reg_box">
                      <CardBody>
                        <div className="register-as">Register As</div>
                        <div className="register-temp">
                          {ConvertFirstLatterToCapital(item?.name)}
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </WrapperRegistration>
  );
}
