import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Facilities from "../../View/Facility";
import Registration from "../../View/Registration";
import Home from "../../View/home";
import Intro from "../../View/intro";
import PrivacyPolicy from "../../View/policy/privacy-policy";
import RefundPolicy from "../../View/policy/refund";
import TermsConditions from "../../View/policy/terms";
import VerifyPage from "../../View/verify-email";

function RoutePath() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:domainName/:trustId" element={<Intro />} />
          <Route path="/register-now" element={<Registration />} />
          <Route path="/facility" element={<Facilities />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          {/* <Route path="/verify-email" element={<VerifyPage />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RoutePath;
